import moment from "moment";
import { isNull, isUndefined, range } from "lodash";
import { getWeeksInfoForSelectedYear } from "./weekly-plan";

// Backend API's List 
export const END_POINTS = {
    USER_DETAILS: {
        GET_LOGGEDIN_USER_DETAILS: 'user-details/?email=emailAddress',
        APP_USERS_LIST: 'user-details/?offset=offsetCount&limit=pageSize',
        APP_USER_EMAILS_LIST: 'user-details/email-list?offset=offsetCount&limit=pageSize'

    },
    MONTHLY_PLAN: {
        GET_DATA: 'countryid/monthly-plan/get-plans?offset=offsetCount&limit=pageSize',
        UPDATE_DATA: 'countryid/monthly-plan/save',
        ADD_SKU: 'countryid/monthly-plan/add',
        ADD_SKU_UPLOAD: 'countryid/monthly-plan/upload-sku',
        DOWNLOAD_UPLOADABLE_DATA: 'countryid/monthly-plan/uploadable-template',
        UPLOAD_PLANS: 'countryid/monthly-plan/upload-plans',
        REMOVE_SKU: 'countryid/monthly-plan/remove-sku',
        RESET_TOTAL_UNITS: 'countryid/monthly-plan/reset',
        EXPORT_DATA: 'countryid/monthly-plan/export'
    },
    WEEKLY_PLAN: {
        GET_DATA: 'countryid/weekly-plan/get-plans?offset=offsetCount&limit=pageSize',
        UPDATE_DATA: 'countryid/weekly-plan/save',
        ADD_SKU: 'countryid/weekly-plan/add',
        ADD_SKU_UPLOAD: 'countryid/weekly-plan/upload-sku',
        DOWNLOAD_UPLOADABLE_DATA: 'countryid/weekly-plan/uploadable-template',
        UPLOAD_PLANS: 'countryid/weekly-plan/upload-plans',
        REMOVE_SKU: 'countryid/weekly-plan/remove-sku',
        RESET_PLANNED_METRIC_KPI_UNITS: 'countryid/weekly-plan/reset',
        EXPORT_DATA: 'countryid/weekly-plan/export'
    },
    FINACIAL_SUMMARY: {
        MONTHLY_PLAN: {
            GET_SUMMARY: 'countryid/monthly-plan/summary?summaryType=summaryId&summaryLevel=summaryLevelId',
            EXPORT_SUMMARY: 'countryid/monthly-plan/export-pcogs-summary',
            GET_MAG_SUMMARY: 'countryid/monthly-plan/summary?summaryType=summaryId&summaryLevel=summaryLevelId&categoryId=CategoryId&year=YearId',
        },
        WEEKLY_PLAN: {
            GET_SUMMARY: 'countryid/weekly-plan/summary?summaryType=summaryId&summaryLevel=summaryLevelId',
            GET_MAG_SUMMARY: 'countryid/weekly-plan/summary?summaryType=summaryId&summaryLevel=summaryLevelId&categoryId=CategoryId&year=YearId',
            EXPORT_SUMMARY: 'countryid/weekly-plan/export-pcogs-summary',
        },
        DEAL_PLAN: {
            SUMMARY: 'countryid/deal-plan/summary?offset=offsetCount&limit=pageSize',
            EXPORT_SUMMARY: 'countryid/deal-plan/summary/export'
        },
        TARGETS: {
            DEVIATION_SUMMARY: 'countryid/targets/deviation-summary?summaryLevel=summaryLevelId',
            SUMMARY: 'countryid/targets/summary',
            EXPORT_SUMMARY: 'countryid/targets/summary/export'
        }
    },
    DEAL_PLAN: {
        GET_DATA: 'countryid/deal-plan/get-plans?offset=offsetCount&limit=pageSize',
        EXPORT_DATA: 'countryid/deal-plan/export',
        ADD_OR_SAVE_DEAL: 'countryid/deal-plan/save',
        DELETE_BULK_DEALS_OR_SELECTED_DEALS: 'countryid/deal-plan/delete',
        UPLOAD_DEALS: 'countryid/deal-plan/upload-deals',
    },
    TARGETS: {
        GET_SELECTED_YEAR_EVENTS: 'countryid/targets/get-events?Year=selectedYear',
        GET_UPLOADABLE_EVENTS: 'countryid/targets/get-uploadable-event?Year=selectedYear',
        GET_TARGETS: 'countryid/targets/get-targets?offset=offsetCount&limit=pageSize',
        UPLOAD_TEMPLATE_COLUMNS: 'countryid/targets/eventId/upload-template-columns-list',
        UPLOAD_TARGETS: 'countryid/targets/upload',
        EXPORT_TARGETS: 'countryid/targets/export',
        ADD_OR_SAVE_TARGETS: 'countryid/targets/save',
        SKU_IN_TARGETS: 'countryid/targets/sku-exist-for-event?Year=selectedYear&EventId=selectedEvent&Sku=SkuName',
        DELETE_DEALS: 'countryid/targets/delete'
    },
    PRICES: {
        GET_PRICES: 'prices/get?offset=offsetCount&limit=pageSize',
        REMOVE_PRICES: 'prices/remove',
        UPLOAD_PRICES: 'prices/upload',
        ADD_OR_SAVE_PRICES: 'prices/save',
        EXPORT_PRICES: 'prices/export'
    },
    BASE: {
        COUNTRY_LIST: 'countries/?offset=offsetCount&limit=pageSize',
        COUNTRY_CATEGORY_LIST: 'categories/for-country/countryid',
        CATEGORY_LIST: 'categories/?offset=offsetCount&limit=pageSize',
        METRIC_KPIS: 'countryid/metric-kpis/?offset=offsetCount&limit=pageSize',
        DEAL_EVENTS_LIST: 'deal/events/?offset=offsetCount&limit=pageSize',
        MASTER_DEAL_EVENTS_LIST: 'deal/events/master?offset=offsetCount&limit=pageSize',
        DEAL_PEAK_EVENTS_LIST: 'deal/event-flags/?offset=offsetCount&limit=pageSize',
        DEAL_TYPE_LIST: 'deal/types/?offset=offsetCount&limit=pageSize',
        DEAL_STATUS_LIST: 'deal/statuses/?offset=offsetCount&limit=pageSize',
        AMAZON_CATEGORY_LIST: 'amazon-categories/?offset=offsetCount&limit=pageSize',
        SKU_DETAILS: 'countryid/sku-details/?Sku=skuName',
        CURRENCY_LIST: 'currencies/year=selectedYear?offset=offsetCount&limit=pageSize',
    },
    ADMIN: {
        SCREENS_LIST: 'admin-panel/screens?offset=offsetCount&limit=pageSize',
        USER_SCOPE_LIST: 'admin-panel/user-scope?offset=offsetCount&limit=pageSize',
        TARGETS_LIST: 'admin-panel/targets?Year=selectedYear&offset=offsetCount&limit=pageSize',
        NEW_PRODUCTS_INTRODUCTION_LIST: 'admin-panel/new-products-information/get?offset=offsetCount&limit=pageSize',
        METRIC_KPIS_LIST: 'admin-panel/metric-kpis?offset=offsetCount&limit=pageSize',
        SAVE_OR_REMOVE_PROMO: 'admin-panel/user_action/promo',
        SAVE_OR_REMOVE_TARGET: 'admin-panel/user_action/target-event',
        SAVE_TARGET_TO_DEAL_PLAN: 'admin-panel/user_action/target-to-deal-plan',
        SAVE_OR_REVOKE_USER: 'admin-panel/user_action/user',
        SAVE_OR_REMOVE_METRIC_KPI: 'admin-panel/user_action/metric-kpi',
        UPLOAD_NEW_PRODUCTS_INTRODUCTION: 'admin-panel/new-products-information/upload/',
        UNBLOCK_TARGET_UPLOAD : 'admin-panel/targets/unblock',
        EXECUTE_STORED_PROCEDURE : 'admin-panel/exec_stored_procedure?sp_name=name_of_sp'

    }
}

// API Error Message to show if an error occurs (any type erro)
export const errorMessage = "Something went wrong. Please try again later";
// Success Message
export const successMessage = "Successful";

export const NoRecordProvidedMessage = 'No record provided';

export const NoRecordsMessage = 'No record available for provided inputs.';

export const NoExportRecordsMessage = 'No record available for exporting';

export const NoRecordModifiedMessage =  `Cannot Save, No record changed`
// API Endpoint failure Message
export const apiNotWorkingMessage = 'Api seems to be not responding, Please Contact the Administrator'

// Ongoing Month Number (Integer Whole-Number)
export const currentMonthNumber = moment().month()
export const currentWeek = moment().week()
export const currentYear = moment().year()
/**
* Array/List Container -> [
    {
        label:<String:MonthName (Example-Format: 'Jan', 'Feb'....)>,
        key: <Integer (WholeNumber): Range(1,12) (Example: 1,2)
    }.....
]
* */
export const monthIdLableList = []

export const monthsUpcoming = []
export const actualMonthRange = (range(1, 13, 1))
actualMonthRange.map(monthNum => {
    monthIdLableList.push(
        { label: moment().month(Number(monthNum - 1)).format('MMM'), key: monthNum }
    )
    return true;
})
const upcomingMonthsNumList = range(currentMonthNumber, 12, 1)
export const upcomingMonthsIdLableList = []
upcomingMonthsNumList.map(monthNum => {
    monthsUpcoming.push(moment().month(Number(monthNum)).format('MMM'))
    upcomingMonthsIdLableList.push({ label: moment().month(Number(monthNum)).format('MMM'), key: monthNum + 1 })
    return true;
})


export const weeksBasedConstantsPerSelectedYear = (selectedYear = currentYear) => ({
    allWeeksNumberList: range(1, getWeeksInfoForSelectedYear(Number(selectedYear)).length + 1, 1),
    allWeeksStringList: allWeeksNumberList.map(week => `Week${week}`),
    allWeeksObjList: allWeeksNumberList.map(week => ({ label: `Week ${week}`, key: week })),
    previousWeeksRange: range(1, currentWeek, 1),
    previousWeeksObjList: previousWeeksRange.map(weekNum => ({
        label: `Week ${Number(weekNum).toString()}`,
        key: Number(weekNum)
    })),
    weeksPreviousNumberList: previousWeeksRange.map(weekNum => Number(weekNum)),
    weeksPreviousStringList: previousWeeksRange.map(weekNum => `Week${Number(weekNum).toString()}`),
    upcomingWeeksRange: range(currentWeek, getWeeksInfoForSelectedYear(Number(selectedYear)).length + 1, 1),
    upcomingWeeksObjList: upcomingWeeksRange.map(weekNum => ({
        label: `Week ${Number(weekNum).toString()}`,
        key: Number(weekNum)
    })),
    weeksUpcomingNumberList: upcomingWeeksRange.map(weekNum => Number(weekNum)),
    weeksUpcomingStringList: upcomingWeeksRange.map(weekNum => `Week${Number(weekNum).toString()}`),
});

export const allWeeksNumberList = range(1, 54, 1);
export const allWeeksStringList = allWeeksNumberList.map(week => `Week${week}`)
export const allWeeksObjList = []
export const previousWeeksObjList = []
export const upcomingWeeksObjList = []
export const weeksPreviousNumberList = []
export const weeksUpcomingNumberList = []
export const weeksPreviousStringList = []
export const weeksUpcomingStringList = []
export const upcomingWeeksRange = range(currentWeek, 54, 1)
export const previousWeeksRange = range(1, currentWeek, 1)
allWeeksNumberList.map(week => {
    allWeeksObjList.push(
        { label: `Week ${week}`, key: week }
    )
    return true;
})
previousWeeksRange.map(weekNum => {
    previousWeeksObjList.push({
        label: `Week ${Number(weekNum).toString()}`,
        key: Number(weekNum)
    })
    weeksPreviousNumberList.push(Number(weekNum))
    weeksPreviousStringList.push(`Week${Number(weekNum).toString()}`)
    return true;
});
upcomingWeeksRange.map(weekNum => {
    upcomingWeeksObjList.push({
        label: `Week ${Number(weekNum).toString()}`,
        key: Number(weekNum)
    })
    weeksUpcomingNumberList.push(Number(weekNum))
    weeksUpcomingStringList.push(`Week${Number(weekNum).toString()}`)
    return true;
});
// Default Monthly-Weekly Plan Financial Summary Data Table Columns List
export const FinancialSummaryTables = [
    { tableId: 'pcogsCySummary', tableName: 'Pcogs Overview', dataSetName: 'currentYear' },
    { tableId: 'pcogsLySummary', tableName: 'Pcogs Ly Overview', dataSetName: 'lastYear' },
    { tableId: 'pcogsCyVsLySummary', tableName: 'Change in Pcogs (Current Year Vs Last Year)', dataSetName: 'diffCurrentVsLast' },
]

// Default Monthly Plan Data Table Columns List
export const monthlyPlanFSColumnsList = [
    'BusinessUnitDesc', ...monthIdLableList.map(cols => cols.label), 'CategoryTotal'
]

// Default Weekly Plan Data Table Columns List
export const weeklyPlanFSColumnsList = [
    'BusinessUnitDesc', ...allWeeksStringList, 'CategoryTotal'
]

// Default Targets Data Table Columns List
export const targetsTableColumnsList = [
    'Category', 'Sku', 'Event', 'DealStartDate', 'DealEndDate',
]

// Default Data Table Page Size (Rows to showcase )
export const DEFAULT_TABLE_PAGE_SIZE = 20;

// Default Data Table Rows Index
export const DEFAULT_OFFSET = 0;

// Deafult Data Table Maximum rows to retrieve per page
export const DEFAULT_BASE_DATA_PAGE_SIZE = 100;


/**
 * Europe ->
 * BE - Belgium , DE - Germany , ES - Spain , FR - France ,
 * IT - Italy , NL - Netherlands ,  PL - Poland , SE - Sweden ,
 * UK - United Kingdom
 * Western Countries -> 
 * BR - Brazil , CA - Canada , MX - Mexico , US - United States
 * Asian -> 
 * IN - India , JP - Japan , KSA - Saudi Arabia , TR - Turkey , SG - Singapore
**/
export const europeanMarketsCountryCodesList = ['BE', 'DE', 'ES', 'FR', 'IT', 'NL', 'PL', 'SE', 'UK']
export const westernMarketsCountryCodesList = ['BR', 'CA', 'MX', 'US']
export const asianMarketsCountryCodesList = ['IN', 'JP', 'KSA', 'TR', 'SG']

export const applicationEditableFields = (!isNull(process.env.REACT_APP_EDITABLE_FILEDS) &&
    !isUndefined(process.env.REACT_APP_EDITABLE_FILEDS) && typeof process.env.REACT_APP_EDITABLE_FILEDS === 'string') ?
    process.env.REACT_APP_EDITABLE_FILEDS.toString().split(',') : []

// export const europeanMarketEditableMertics = [
//     "Planned Sell Out Units - Total (by e-com Mgr.)"
// ]

// export const westernMarketEdiableMetrics = [
//     "Planned Base Shipped Units",
//     "Planned Conversion Rate",
//     "Seasonal Map Promotions",
//     "Planned Media Boost Units"
// ]


export const CURRENCY_UNITS_LIST = ["Thousands(K)", "Actual", "Millions(M)"];

export const toMillions = (value) => {
    return value > 0
        ? parseFloat(parseFloat(Math.abs(value) / 1000000).toFixed(3))
        : parseFloat(parseFloat(Math.abs(value) / 1000000).toFixed(3) * -1);
};

export const toThousands = (value) => {
    return value > 0
        ? parseFloat(parseFloat(Math.abs(value) / 1000).toFixed(2))
        : parseFloat(parseFloat(Math.abs(value) / 1000).toFixed(2) * -1);
};

export const CURRENCY_UNITS_OBJECT = [
    {key: 1, label: 'Actuals', format: '', conversionMethod: null},
    {key: 2, label: 'Thousand (K)', format: 'K', conversionMethod: toThousands },
    {key: 3, label: 'Million (M)', format: 'M', conversionMethod: toMillions}
]